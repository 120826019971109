export const versions = {
  currentVersion: '3.0.6',
  logVersion: [
    { v: '3.0.6', log: ['[Bugfix] PDF-Bericht mit Sonderzeichen'] },
    { v: '3.0.5', log: ['[Bugfix] Updaten von Aufgaben'] },
    { v: '3.0.5', log: ['[Erledigt] Ladespinner bei langen Abfragen'] },
    { v: '3.0.4', log: ['[Erledigt] Erstellungsdatum von Bildern in Aufgaben und im Bericht'] },
    { v: '3.0.3', log: ['[Erledigt] Information Erweiterung', '[Erledigt] Porsche QR-Codes'] },
    { v: '3.0.2', log: ['[Bugfix] Gelöschte Fotos werden hochgeladen', '[Optimierung] Springen beim Quickdone'] },
    { v: '3.0.1', log: ['[Bugfix] Fotos werden doppelt hochgeladen', '[Bugfix] Schrift verschoben im Bericht'] },
    { v: '3.0.0', log: ['[Erledigt] Start der Mandanten-Beta', '[Bugfix] Bericht hat einen Zeilenumbruch, bei langen Texten', '[Erledigt] Bilder können in Vollbild geöffnet werden', '[Erledigt] Behinderungen können gelöscht werden'] },
    { v: '2.1.5', log: ['[Erledigt] Gefilterte Aufgaben mit der rightid = 0 (Überaufgabe) werden nicht mehr angezeigt.'] },
    { v: '2.1.4', log: ['[Erledigt] In der Auswettung bei den Behinderungen werden nun Unter- und Überaufgaben getrennt angezeigt.', '[Bugfixes] Typo in der Berichtserstattung'] },
    { v: '2.1.3', log: ['[Erledigt] Dropdown für den Standort', '[Bugfixes] Einige kleinere Fehler behoben'] },
    { v: '2.1.2', log: ['[Bugfixes] Einige kleinere Fehler behoben'] },
    { v: '2.1.1', log: ['[Bugfixes] Einige kleinere Fehler behoben'] },
    { v: '2.1.0', log: ['[Erledigt] Benutzerverwaltung für Administratoren', '[Erledigt] Jeder Benutzer kann sein Profil überarbeiten', '[Bugfix] Datum im Bericht an die Zeitzone angepasst', '[Bugfix] Text für eine Behinderung auf das richtige Feld gesetzt', '[Bugfix] Optimierung des Scans'] },
    { v: '1.2.4', log: ['[Erledigt] Scanfunktion an neues Muster angepasst'] },
    { v: '1.2.3', log: ['[Erledigt] Zusammenfassung zu "Einstellungen"'] },
    { v: '1.2.2', log: ['[Gelöst] Bugfix der Dashboard-Selektierung'] },
    { v: '1.2.1', log: ['[Erledigt] Projektrefresh nach einem Import'] },
    { v: '1.2.0', log: ['[Erledigt] Verknüpfung von Aufgaben'] },
    { v: '1.1.1', log: ['[Gelöst] Bugfixes diverser Filterbugs'] },
    { v: '1.0.19', log: ['[Erledigt] Neu: Es lässt sich eine Überaufgabe als PDF exportieren'] },
    { v: '1.0.18', log: ['[Erledigt] Neu: Der Import lässt nun inkrementelle Updates zu'] },
    { v: '1.0.17', log: ['[Erledigt] Neu: Fotos können gelöscht werden'] },
    { v: '1.0.16', log: ['[Erledigt] Neu: Fotofunktion'] },
    { v: '1.0.15', log: ['[Erledigt] Neu: Beschreibungstext verlängert', '[Erledigt] Neu: Unteraufgaben nach ID sortiert', '[Erledigt] Neu: Beschreibung wird beim Import berücksichtigt'] },
    { v: '1.0.14', log: [' [Erledigt] Neu: Im Dashboard wird ein Filterlink zu den Aufgaben angezeigt (AV1-2020-06)'] },
    { v: '1.0.13', log: [' [Erledigt] Neu: Bei Statusänderungen einer Unteraufgabe übernimmt die Überaufgabe ggf. den Status (AV1-2020-003)', '[Erledigt] Neu: Bei Statusänderung einer Überaufgabe übernehmen alle Unteraufgaben den Status (AV1-2020-003)'] },
    { v: '1.0.12', log: ['[Gelöst] Bug: Rechtsschreibfehler'] },
    { v: '1.0.11', log: ['[Erledigt] Neu: In Aufgaben additiver Suchfilter anhand des Status (AV1-2020-004)'] },
    { v: '1.0.10', log: ['[Erledigt] Neu: Bei einem erfolgreichem QR-Scan wird die Überaufgabe geöffnet (AV1-2020-008)'] },
    { v: '1.0.9', log: ['[Erledigt] Neu: In Aufgaben sind in den Unteraufgaben die Quick-Buttons "In Bearbeitung" und "Erledigt" (AV1-2020-009)'] },
    { v: '1.0.8', log: ['[Erledigt] Neu: Autologin innerhalb von 24h. (AV1-2020-010)'] },
    { v: '1.0.7', log: ['[Gelöst] Bug: Unter Projekte können Aufgaben bearbeitet werden', '[Erledigt] Neu: Unter Filter in Projekte -> Aufgaben beim Öffnen zurückgesetzt'] },
    { v: '1.0.6', log: ['[Gelöst] Bug: Bei einer gefilterten Liste wird die richtige Unteraufgabe geöffnet'] },
    { v: '1.0.5', log: ['[Gelöst] Bug: Beim Filtern gibt es einen grauen Status'] },
    { v: '1.0.4', log: ['[Erledigt] Neu: Benutzerauswahl wurde zu Rollenauswahl geändert'] },
    { v: '1.0.3', log: ['[Erledigt] Neu: Der Status der Unteraufgaben wird farblich unterstrichen (AV1-2020-001)', '[Erledigt] Neu: Die Aufgaben können vom PL / BL nach Benutzern gefiltert werden (AV1-2020-001)'] },
    { v: '1.0.2', log: ['[Erledigt] Neu: Sortierung des Dashboards angepasst'] },
    { v: '1.0.1', log: ['[Erledigt] Neu: Dashboard (AV1-2020-002)'] },
    { v: '1.0.0', log: ['[Erledigt] Neu: Version 0.5.0 verlässt den Beta-Status zur Version 1.0.0]'] },
    { v: '0.0.1', log: ['Pre-Alpha-Phase..>'] }
  ]
}
